import React, { Fragment } from "react"
import { Layout } from '@components/Layout'
import { mapToSEO, SEO } from "@components/SEO";
import { Banner, mapToPageHeader } from "@components/Banner";
import { graphql } from "gatsby";
import { mapToKiwiVIPVisible, SignupBannerWrapper } from "@components/SignupBanner";
import StyledQuickCombinationFinder from "@components/CombinationFinder/StyledQuickCombinationFinder";
import { mapToStyledCombinationFinder } from "@components/CombinationFinder/mapper";
import { FaqList, mapToFaqContainer } from "@components/FaqList";
import theme from "@styles/theme";
import { GenericBannerContainer } from "@components/GenericBannerContainer";
import PricingImageCardContainer from "@components/PricingImageCard/PricingImageCardContainer";
import { mapToPricingImageCardContainer } from "@mappers/PricingImageCard/PricingImageCard.mapper";
import { mapToPlateImagesBlock } from "@components/PlateImagesBlock/mapper";
import PlateImagesBlock from "@components/PlateImagesBlock/PlateImagesBlock";
import { ImageButtonBanner, mapToImageLinkBanner } from "@components/ImageButtonBanner";
import { ImageInformationBanner, mapToImageInfoBanner } from "@components/ImageInformationBanner";
import { AdditionalContentKey, AdditionalContentValue, AdditionalSection } from "@components/ImageInformationBanner/ImageInformationBanner.styles";
import { mapToPlateSizeGuideBanner } from "@mappers/PlateSizeGuideBanner/PlateSizeGuideBanner.mapper";
import PlateSizeGuideBanner from "@components/PlateSizeGuideBanner/PlateSizeGuideBanner";
import { Responsive } from "semantic-ui-react";
import ImageGridContainer from "@components/ImageGrid/ImageGridContainer";
import { mapToImageGridContainer } from "@mappers/ImageGrid/ImageGrid.mapper";
import { mapToFluidImageGridBannerContainer } from "@mappers/FluidImageGridBanner/FluidImageGridBanner.mapper";
import FluidImageGridBanner from "@components/FluidImageGridBanner/FluidImageGridBanner";

interface Props {
  data: any
}

interface State {

}

interface KontentCustomDictionary {
  elements: {
    key: {
      value: string
    }
    value: {
      value: string
    }
  }
}

class ProductLandingPage extends React.Component<Props, State>{
  constructor(props: Props) {
    super(props);

  }

  renderAdditionalContent = (additionalContent: KontentCustomDictionary[]) => {
    return (
      <Fragment>
        {
          additionalContent && additionalContent.length > 0 &&
          <AdditionalSection>
            {
              additionalContent.map((item, i) => {
                const { key, value } = item.elements;
                return (<div>
                  <AdditionalContentKey>
                    {key.value}:
                  </AdditionalContentKey>
                  <AdditionalContentValue>
                    {value.value}
                  </AdditionalContentValue></div>
                )
              })
            }
          </AdditionalSection>

        }
      </Fragment>
    )
  }
  render() {
    const { elements } = this.props.data.kontentItemProductLandingPage;

    const metadata = mapToPageHeader(elements);
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const reCaptchaSiteKey = this.props.data.site.siteMetadata.reCaptchaSiteKey;
    const reCaptchaSiteKeyV2 = this.props.data.site.siteMetadata.reCaptchaSiteKeyV2;
    const seoData = mapToSEO(elements);
    const styledCombinationFinder = mapToStyledCombinationFinder(elements);
    styledCombinationFinder.apiUrl = apiUrl;
    const kiwiVip = mapToKiwiVIPVisible(elements);
    const faqData = mapToFaqContainer(elements.faq.linked_items[0].elements);
    const plateSizeGuideBannerData = mapToPlateSizeGuideBanner(elements.plate_sizing_guide.linked_items[0].elements);
    const pricingImageContainerData = mapToPricingImageCardContainer(elements.pricing_image_card.linked_items[0].elements);
    const imageGridContainerData = mapToImageGridContainer(elements.image_grid.linked_items[0].elements);
    const infoBannerData = elements.info_image_section.linked_items[0] == undefined ? undefined : mapToImageInfoBanner(elements.info_image_section.linked_items[0].elements);
    const additionalContent = infoBannerData == undefined ? undefined : this.renderAdditionalContent(infoBannerData.additionalLinkedItems);
    const fluidImageGridBannerData = mapToFluidImageGridBannerContainer(elements.fluid_image_grid_banner.linked_items[0].elements);

    const plateImages = elements.plate_images.linked_items.length > 0 ? mapToPlateImagesBlock(elements.plate_images.linked_items[0].elements) : null;
    const imageButtonBannerData = elements.image_banner.linked_items.length > 0 ? mapToImageLinkBanner(elements.image_banner.linked_items[0].elements) : null;

    const plateTranslation = elements.plate_translation.linked_items.length > 0 ? mapToPlateImagesBlock(elements.plate_translation.linked_items[0].elements) : null;

    return (
      <Layout version="standard">
        <SEO {...seoData} />
        <Banner {...metadata} />

        {
          plateImages &&
          <GenericBannerContainer padding={{ mobile: { top: 55, bottom: 60 }, desktop: { top: 90, bottom: 100 } }} backgroundColor={plateImages.backgroundColor}>
            <PlateImagesBlock {...plateImages} />
          </GenericBannerContainer>
        }
        {
          infoBannerData !== undefined &&
          <GenericBannerContainer padding={{ mobile: { top: 10, bottom: 54 }, desktop: { top: 85, bottom: 80 } }} removeContainer={true} backgroundColor={infoBannerData.backgroundColor}>
            <ImageInformationBanner verticalPosition='middle' {...infoBannerData} additionalContent={additionalContent} />
          </GenericBannerContainer>
        }
        <GenericBannerContainer padding={{ mobile: { top: 50, bottom: 40 }, desktop: { top: 85, bottom: 80 } }} backgroundColor={theme.brand.colors.green}>
          <StyledQuickCombinationFinder {...styledCombinationFinder} reCaptchaSiteKey={reCaptchaSiteKey} reCaptchaSiteKeyV2={reCaptchaSiteKeyV2}/>
        </GenericBannerContainer>

        {
          imageButtonBannerData &&
          <ImageButtonBanner {...imageButtonBannerData} />
        }

        {
          plateTranslation &&
          <GenericBannerContainer padding={{ mobile: { top: 55, bottom: 60 }, desktop: { top: 90, bottom: 100 } }} backgroundColor={plateTranslation.backgroundColor}>
            <PlateImagesBlock {...plateTranslation} />
          </GenericBannerContainer>
        }
        <GenericBannerContainer padding={{ mobile: { top: 50, bottom: 30 }, desktop: { top: 85, bottom: 95 } }} backgroundColor={theme.brand.colors.lightGrey}>
          <PlateSizeGuideBanner {...plateSizeGuideBannerData}></PlateSizeGuideBanner>
        </GenericBannerContainer>

        {
          fluidImageGridBannerData && fluidImageGridBannerData.images.length >= 7 &&
          <GenericBannerContainer removeContainer={true} padding={{ mobile: { top: 0, bottom: 0 }, desktop: { top: 0, bottom: 0 } }} backgroundColor={theme.brand.colors.lightGrey}>
            <FluidImageGridBanner {...fluidImageGridBannerData}></FluidImageGridBanner>
          </GenericBannerContainer>
        }

        <GenericBannerContainer padding={{ mobile: { top: 55, bottom: 60 }, desktop: { top: 90, bottom: 100 } }} backgroundColor={theme.brand.colors.lightGrey}>
          <PricingImageCardContainer headingColor={theme.brand.colors.black} subHeadingColor={theme.brand.colors.darkGrey} {...pricingImageContainerData} />
        </GenericBannerContainer>
        {/* <GenericBannerContainer
          padding={{
            mobile: {
              top: 68,
              bottom: 30
            },
            desktop: {
              top: 102,
              bottom: 60
            }
          }}
          backgroundColor={imageGridContainerData.backgroundColour}>
          <ImageGridContainer textColor='white' headingColor='white' subHeadingColor='white' {...imageGridContainerData} />
        </GenericBannerContainer> */}

        <GenericBannerContainer padding={{ mobile: { top: 50, bottom: 60 }, desktop: { top: 90, bottom: 100 } }} backgroundColor={theme.brand.colors.beige} containerMaxWidth={1123}>
          <FaqList borderColor={theme.brand.colors.black} questionColor={theme.brand.colors.black} answerColor={theme.brand.colors.black} {...faqData} />
        </GenericBannerContainer>
        {
          kiwiVip.visible &&
          <SignupBannerWrapper version={kiwiVip.version} />
        }
      </Layout>
    )
  }
}
export const query = graphql`
query($slug:String!){
    site{
        siteMetadata{
            apiUrl
            reCaptchaSiteKey
            reCaptchaSiteKeyV2
        }
    }
    kontentItemProductLandingPage(fields: {slug: {eq: $slug}}) {
        system{
            name
        }
        elements{
          general_page_content__title_position{
            value{
              codename
            }
          }
          fluid_image_grid_banner {
            linked_items {
              ... on KontentItemFluidImageGridBannerContainer {
                elements {
                  image_1 {
                    value {
                      url
                      description
                    }
                  }
                  image_2 {
                    value {
                      url
                      description
                    }
                  }
                  image_3 {
                    value {
                      url
                      description
                    }
                  }
                  image_4 {
                    value {
                      url
                      description
                    }
                  }
                  image_5 {
                    value {
                      url
                      description
                    }
                  }
                  image_6 {
                    value {
                      url
                      description
                    }
                  }
                  image_7 {
                    value {
                      url
                      description
                    }
                  }
                }
              }
            }
          }
          image_grid {
            linked_items {
              ... on KontentItemImageGridContainer {
                id
                elements {
                  background_colors {
                    value {
                      codename
                      name
                    }
                  }
                  heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                  image_grid_items {
                    linked_items {
                      ... on KontentItemImageGridItem {
                        id
                        elements {
                          body {
                            value
                          }
                          heading {
                            value
                          }
                          image {
                            value {
                              url
                              description
                            }
                          }
                          url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          plate_sizing_guide {
            linked_items {
              ... on KontentItemPlateSizeGuideBanner {
                id
                elements {
                  button__button_color_theme {
                    value {
                      codename
                      name
                    }
                  }
                  button__button_type {
                    value {
                      name
                      codename
                    }
                  }
                  button__text {
                    value
                  }
                  display_plate_image_size_in_ratio{
                    value{
                      codename
                    }
                  }
                  button__url_slug {
                    value
                  }
                  heading {
                    value
                  }
                  plates {
                    linked_items {
                      ... on KontentItemPlateHoleGuidePlate {
                        id
                        elements {
                          description {
                            value
                          }
                          plate_image {
                            value {
                              description
                              url
                            }
                          }
                          size_height {
                            value
                          }
                          size_width {
                            value
                          }
                          title {
                            value
                          }
                        }
                      }
                    }
                  }
                  sub_heading {
                    value
                  }
                }
              }
            }
          }
          general_page_content__responsive_header_image {
            linked_items {
              ... on KontentItemBannerImage {
                id
                elements {
                  desktop {
                    value {
                      url
                      description
                    }
                  }
                  mobile {
                    value {
                      description
                      url
                    }
                  }
                  tablet {
                    value {
                      description
                      url
                    }
                  }
                }
              }
            }
          }
          seo__page_description{
            value
          }
          general_page_content__title{
            value
          }
          seo__nofollow{
            value{
              codename
            }
          }
          seo__noindex{
            value{
              codename
            }
          }
          url{
            value
          }
          seo__page_title{
            value
          }
          general_page_content__kiwivip_signup_version{
            value{
              codename
            }
          }
          general_page_content__kiwivip_signup{
            value{
              codename
            }
          }
          info_image_section {
            linked_items {
              ... on KontentItemInfoImageBanner {
                id
                elements {
                  background_colors {
                    value {
                      codename
                      name
                    }
                  }
                  additional_content{
                    linked_items{
                      ... on KontentItemCustomDictionary{
                        elements{
                          key{
                            value
                          }
                          value{
                            value
                          }
                        }
                      }
                    }
                  }
                  button__button_color_theme {
                    value {
                      codename
                      name
                    }
                  }
                  button__button_type {
                    value {
                      codename
                      name
                    }
                  }
                  button__text {
                    value
                  }
                  button__url_slug {
                    value
                  }
                  description {
                    value
                  }
                  image {
                    value {
                      url
                      description
                    }
                  }
                  image_description {
                    value
                  }
                  info_image_banner___image_position {
                    value {
                      codename
                      name
                    }
                  }
                  text_colors {
                    value {
                      codename
                      name
                    }
                  }
                  title {
                    value
                  }
                }
              }
            }
          }
          image_banner{
            linked_items{
              ... on KontentItemImageLinkBanner{
                elements {
                  image {
                    value {
                      url
                    }
                  }
                  image_size{
                    value{
                      codename
                    }
                  }
                  title {
                    value
                  }
                  button__text {
                    value
                  }
                  button__button_type {
                    value {
                      codename
                    }
                  }
                  button__button_color_theme {
                    value {
                      codename
                    }
                  }
                  button__url_slug {
                    value
                  }
                }
              }
            }
          }
          faq {
            linked_items {
              ... on KontentItemFaqBlock {
                elements {
                  background_colors {
                    value {
                      codename
                      name
                    }
                  }
                  faqs {
                    linked_items {
                      ... on KontentItemFaqItem {
                        elements {
                          answer {
                            value
                          }
                          faq_category {
                            value {
                              codename
                              name
                            }
                          }
                          question {
                            value
                          }
                        }
                      }
                    }
                  }
                  title {
                    value
                  }
                }
              }
            }
          }
          plate_images{
            linked_items{
              ...on KontentItemPlateImagesBlock{
                elements{
                  plate_images{
                    value{
                      url
                      description
                      width
                      height
                    }
                  }
                  title{
                    value
                  }
                  background_colors{
                    value{
                      codename
                    }
                  }
                }
              }
            }
          }
          plate_translation{
            linked_items {
              ... on KontentItemPlateImagesBlock {
                elements {
                  plate_images {
                    value {
                      url
                      description
                      width
                      height
                    }
                  }
                  title {
                    value
                  }
                  background_colors {
                    value {
                      codename
                    }
                  }
                }
              }
            }
          }
          pricing_image_card {
            linked_items {
              ... on KontentItemPricingImageCardContainer {
                id
                elements {
                  background_colors {
                    value {
                      codename
                      name
                    }
                  }
                  heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                  pricing_image_cards {
                    linked_items {
                      ... on KontentItemPricingImageCard {
                        id
                        elements {
                          body {
                            value
                          }
                          button__button_color_theme {
                            value {
                              codename
                              name
                            }
                          }
                          button__button_type {
                            value {
                              codename
                              name
                            }
                          }
                          button__text {
                            value
                          }
                          button__url_slug {
                            value
                          }
                          heading {
                            value
                          }
                          image {
                            value {
                              url
                              description
                            }
                          }
                          price {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          combination_search{
            linked_items{
              ... on KontentItemStyledCombinationSearch{
                elements{
                  title{
                    value
                  }
                  description{
                    value
                  }
                  only_for_car{
                    value{
                      codename
                    }
                  }
                  button__text {
                    value
                  }
                  button__button_color_theme {
                    value {
                      name
                      codename
                    }
                  }
                  button__button_type {
                    value {
                      codename
                    }
                  }
                  button__url_slug {
                    value
                  }
                  plate_background_image{
                    value{
                      url
                      description
                      name
                      width
                      height
                    }
                  }
                  combination_top_margin_ratio{
                    value
                  }
                }
              }
            }
          }
        }
    }
}
`

export default ProductLandingPage